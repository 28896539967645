@use 'breakpoints.scss';
@use 'various.scss';

.authLinks {
    display: none;
}

/* Applies to tablets (+768px) */
@media screen and (breakpoints.$mdUp) {
    .authLinks {
        display: inline-flex;

        margin-left: 0.5em !important;

        a {
            margin-left: 0.40em !important;
            margin-right: 0.40em !important;
        }
    }
}